import { ReactNode, forwardRef } from 'react';
import Badge, { BadgeProps } from 'components/Badge/Badge';
import { classNames } from 'utils/util';
import './card.scss';

interface Header {
	heading: ReactNode;
	action?: ReactNode;
	subText?: string;
}

interface CardProps {
	className?: string;
	header?: Header;
	badges?: BadgeProps[];
	children: ReactNode;
	style?: React.CSSProperties;
}

const Card = forwardRef<HTMLDivElement, CardProps>(({ className, header, children, style, badges = [] }, ref) => {
	const classes = classNames(['ph-card', className]);
	const headerExists = !!header;

	return (
		<div className={classes} ref={ref} style={style}>
			{headerExists && (
				<div className="ph-card-header">
					<div className="ph-card-header-left-container">
						<div className="ph-card-title">
							<h4>{header.heading}</h4>
							{badges.length > 0 && badges.map((badge) => <Badge {...badge} key={badge.content} />)}
						</div>
						{header.subText && <div className="ph-card-subText">{header.subText}</div>}
					</div>
					{!!header.action && <div className="ph-card-actions">{header.action}</div>}
				</div>
			)}
			<div className={classNames(['ph-card-body'])}>{children}</div>
		</div>
	);
});

Card.displayName = 'Card';

export default Card;

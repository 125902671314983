import React, { useRef, useState } from 'react';
import './modal.scss';
import { classNames, generateUUID } from 'utils/util';
import Button from 'components/Button';
import IconButton from 'components/IconButton';
import { useAnimationEnd } from 'utils/hooks';
import ReactDOM from 'react-dom';
import { faXmark as faLightXmark } from '@fortawesome/pro-light-svg-icons/faXmark';
import { library } from 'components/Icons/Icon';

export const SizeTypes = ['small', 'medium', 'large'] as const;
export type SizeType = (typeof SizeTypes)[number];

library.add(faLightXmark);

interface ModalProps {
	show: boolean;
	title: string;
	showCancelButton?: boolean;
	showHeader?: boolean;
	showFooter?: boolean;
	buttonSubmitText?: string;
	buttonCancelText?: string;
	buttonSubmitDisabled?: boolean;
	size?: SizeType;
	children: React.ReactNode;
	onSubmit?: () => void;
	onClose?: () => void;
	onCancel?: () => void;
	className?: string;
	isSubmitting?: boolean;
	modalClosable?: boolean;
}

const Modal: React.FC<ModalProps> = ({
	show,
	title,
	showCancelButton = true,
	showHeader = true,
	showFooter = true,
	buttonSubmitText,
	buttonCancelText,
	buttonSubmitDisabled,
	size = '',
	children,
	onSubmit,
	onClose,
	onCancel,
	className,
	isSubmitting: isSubmittingProp,
	modalClosable = true,
}) => {
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [isClosed, setIsClosed] = useState(false);
	const [shouldRender, setShouldRender] = useState(show);
	const modalRef = useRef<HTMLDivElement>(null);

	useAnimationEnd(modalRef, show, (render: boolean) => setShouldRender(render));

	const submitModal = async () => {
		setIsSubmitting(true);

		if (onSubmit) {
			await onSubmit();
		}

		setIsSubmitting(false);
		setIsClosed(true);
	};

	const cancelModal = () => {
		if (onCancel) {
			onCancel();
		} else if (onClose) {
			onClose();
		}
		setIsClosed(true);
	};

	const closeModal = () => {
		if (onClose) {
			onClose();
		}
		setIsClosed(true);
	};

	const classes = classNames([
		'ph-modal',
		{
			'ph-open': show,
			'ph-close': !show,
			'ph-fadeout': isClosed,
		},
		className,
	]);

	const contentClasses = classNames([
		'ph-modal-content',
		{
			'ph-open': show,
			'ph-close': !show,
			'ph-fadeout': isClosed,
		},
	]);

	if (!shouldRender) {
		return null;
	}

	const uuid = generateUUID();

	let buttonSubmitState = 'isNormal';
	if (buttonSubmitDisabled) {
		buttonSubmitState = 'isDisabled';
	} else if (isSubmitting || isSubmittingProp) {
		buttonSubmitState = 'isProcessing';
	}

	const modalContent = (
		<div ref={modalRef} className={classes}>
			<div
				className={`${contentClasses} ph-modal-content-${size}`}
				role="dialog"
				aria-labelledby={`modal-title${uuid}`}
			>
				{showHeader && (
					<div className={'ph-modal-header'}>
						<h4 id={`modal-title${uuid}`}>{title}</h4>
						{modalClosable && (
							<IconButton aria-label="Close" icon={['fal', 'xmark']} onClick={closeModal} />
						)}
					</div>
				)}
				<div className={'ph-modal-body'}>{children}</div>
				{showFooter && (
					<div className={'ph-modal-footer'}>
						{showCancelButton && (
							<Button type="secondary" buttonText={buttonCancelText ?? 'Cancel'} onClick={cancelModal} />
						)}
						<Button
							type="primary"
							state={buttonSubmitState}
							buttonText={buttonSubmitText ?? 'Submit'}
							onClick={submitModal}
						/>
					</div>
				)}
			</div>
		</div>
	);

	return ReactDOM.createPortal(modalContent, document.body);
};

export default Modal;

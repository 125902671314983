import { useState, useEffect, useContext } from 'react';
import { FeatureConfigContext, FeatureConfigContextProps, FeatureConfigKeyValue } from 'components/FeatureConfig';
import apiClient, { ApiClient } from 'api/api';
import { GET_FEATURE_CONFIG_URL } from './utils/constants';
import { FeatureConfigGetAllRepsonse } from './utils/interfaces';
import { getUrlComponents } from 'utils/util';

let client: ApiClient;

function matchConfigs(configKeys: string[], storedConfigs: FeatureConfigKeyValue[]): FeatureConfigKeyValue[] {
	return storedConfigs.filter((storedConfig) => configKeys.includes(storedConfig.key));
}

function verifyAndSetupApiClient(baseUrl: string | null, featureConfigContext: FeatureConfigContextProps) {
	if (!client) {
		if (!baseUrl && !featureConfigContext.portalUrl) {
			throw new Error('Please provide a base URL');
		}

		const { baseURL, path: pathPrefix } = getUrlComponents(baseUrl ?? featureConfigContext.portalUrl);

		client = apiClient(baseURL, pathPrefix);
	}
}

function useFeatureConfig(configKeys: string[], baseUrl: string | null, featureConfigUrl?: string) {
	const [configValues, setConfigValues] = useState([] as FeatureConfigKeyValue[]);
	const featureConfigContext = useContext(FeatureConfigContext);

	verifyAndSetupApiClient(baseUrl, featureConfigContext);

	useEffect(() => {
		fetchData();
	}, []);

	async function fetchData() {
		const storedConfigs = featureConfigContext.configList;
		// check config in context first
		if (storedConfigs && storedConfigs.length > 0) {
			setConfigValues(matchConfigs(configKeys, storedConfigs));
			return;
		}

		if (!configKeys || configKeys.length === 0) return;

		try {
			const responseData = await client.get<FeatureConfigGetAllRepsonse[]>(
				featureConfigUrl || GET_FEATURE_CONFIG_URL
			);
			const configs = [] as FeatureConfigKeyValue[];
			const configsForContext = [] as FeatureConfigKeyValue[];

			responseData.forEach((dataItem) => {
				const keyValue: FeatureConfigKeyValue = {
					key: dataItem.key,
					value: dataItem.actualValue.value,
				};

				configsForContext.push(keyValue);

				if (configKeys.find((key) => dataItem.key == key)) {
					configs.push(keyValue);
				}
			});

			setConfigValues(configsForContext);
			featureConfigContext.setConfigList(configsForContext);
		} catch (error) {
			console.error(error);
		}
	}

	return configValues;
}

export default useFeatureConfig;

import { useState, useEffect, useRef } from 'react';
import apiClient, { ApiClient } from 'api/api';
import { removeUrlLastTrailingSlashes, getUrlComponents } from 'utils/util';
import { COOKIE_NAMES, getCookie, setCookie, deleteCookie } from 'utils/cookieUtil';

// Expose global object from outside of this application
declare global {
	interface Window {
		heap?: {
			resetIdentity: () => void;
		};
	}
}

let client: ApiClient;

export interface UseUserSession {
	showSignInScreen: boolean;
	disableLogOutButton: boolean;
	signOut: (urlParam?: string) => void;
	changeOrg: (orgId: string) => void;
	tsmLogOut: () => void;
	manageAccountUrl: string;
}

const setUserCookies = (currentUserIdOrgIdRef: React.MutableRefObject<string>, domainName: string) => {
	//Init COOKIE_CURRENT_USERID_ORGID after page load.
	if (currentUserIdOrgIdRef.current) {
		setCookie(COOKIE_NAMES.CURRENT_USERID_ORGID, currentUserIdOrgIdRef.current, domainName);
	}
	const tempUserIdOrgId = getCookie(COOKIE_NAMES.CURRENT_USERID_ORGID);
	if (tempUserIdOrgId != undefined) {
		currentUserIdOrgIdRef.current = tempUserIdOrgId;
	}

	//Init COOKIE_LASTEST_ACTIVITY_DETECTED_DATETIME and COOKIE_DISABLE_LOGOUT_BUTTON for first load
	if (currentUserIdOrgIdRef.current && getCookie(COOKIE_NAMES.LATEST_ACTIVITY_DETECTED_DATETIME) == undefined) {
		setCookie(COOKIE_NAMES.LATEST_ACTIVITY_DETECTED_DATETIME, Date.now().toString(), domainName);
		setCookie(COOKIE_NAMES.DISABLE_LOGOUT_BUTTON, '0', domainName);
	}
};

const checkIdleTime = (
	logOutTimeout: number,
	checkActivityRef: React.MutableRefObject<boolean>,
	setShowSignInScreen: (value: React.SetStateAction<boolean>) => void
) => {
	//Check idle time in minute.
	const latestActivityDetectedDateTime = parseInt(getCookie(COOKIE_NAMES.LATEST_ACTIVITY_DETECTED_DATETIME) ?? '0');
	const datediff = Math.floor((Date.now() - latestActivityDetectedDateTime) / (1000 * 60));

	if (getCookie(COOKIE_NAMES.CURRENT_USERID_ORGID) == undefined || datediff > logOutTimeout) {
		setShowSignInScreen(true);
		checkActivityRef.current = true;
	} else {
		setShowSignInScreen(false);
		checkActivityRef.current = false;
	}
};

const logoutOfTsm = (portalUrl: string): void => {
	const portalBaseUrl = removeUrlLastTrailingSlashes(portalUrl);
	window.location.href = `${portalBaseUrl}/TechSupport/logout`;
};

const signOutUser = (
	isSigningOutRef: React.MutableRefObject<boolean>,
	portalUrl: string,
	domainName: string,
	urlParam: string | undefined
) => {
	//avoil multiple clickings on Signout button
	if (isSigningOutRef.current) {
		return;
	}
	isSigningOutRef.current = true;

	setCookie(COOKIE_NAMES.DISABLE_LOGOUT_BUTTON, '1', domainName);

	let url = `${removeUrlLastTrailingSlashes(portalUrl)}/login/signout`;
	if (urlParam) {
		url = `${url}?${urlParam}`;
	}

	// Need to reset Heap identity on sign out see:
	// https://developers.heap.io/reference/resetidentity
	if(window.heap) window.heap.resetIdentity();

	deleteCookie(COOKIE_NAMES.LATEST_ACTIVITY_DETECTED_DATETIME, domainName);
	deleteCookie(COOKIE_NAMES.CURRENT_USERID_ORGID, domainName);

	window.location.href = url;
};

const checkAndDisableBackToSignInButton = (
	disableLogOutButtonRef: React.MutableRefObject<boolean>,
	setDisableLogOutButton: (value: React.SetStateAction<boolean>) => void
) => {
	const disableLogoutButtonValue = getCookie(COOKIE_NAMES.DISABLE_LOGOUT_BUTTON);
	if (disableLogoutButtonValue === '1' && !disableLogOutButtonRef.current) {
		disableLogOutButtonRef.current = true;
		setDisableLogOutButton(true);
	} else if (disableLogOutButtonRef.current) {
		disableLogOutButtonRef.current = false;
		setDisableLogOutButton(false);
	}
};

const hasOrgChanged = (currentCookieUserOrgId: string | undefined, previousUserIdOrgId: string) => {
	return (
		currentCookieUserOrgId !== undefined &&
		previousUserIdOrgId !== '' &&
		previousUserIdOrgId !== currentCookieUserOrgId
	);
};

function useUserSession(
	isPlatformLogin: string,
	logOutTimeout: number,
	portalUrl: string,
	apiUrl: string,
	userId: string,
	orgId: string,
	domainName: string
): UseUserSession {
	const [disableLogOutButton, setDisableLogOutButton] = useState(false);
	const [showSignInScreen, setShowSignInScreen] = useState(false);

	const checkActivityRef = useRef(false);
	const isSigningOutRef = useRef(false);
	const currentUserIdOrgIdRef = useRef(`${userId}:${orgId}`);
	const disableLogOutButtonRef = useRef(false);

	const manageAccountUrl = `${removeUrlLastTrailingSlashes(portalUrl)}/userprofile/manageaccount`;

	const { baseURL, path: pathPrefix } = getUrlComponents(apiUrl);

	if (!client) {
		client = apiClient(baseURL, removeUrlLastTrailingSlashes(pathPrefix));
	}

	//Events
	const setActivityDetectedTime = () => {
		if (checkActivityRef.current) return;
		setCookie(COOKIE_NAMES.LATEST_ACTIVITY_DETECTED_DATETIME, Date.now().toString(), domainName);
	};

	//this useEffect is run once to init interval to mornitor time to show "Back to Login" screen.
	useEffect(() => {
		setUserCookies(currentUserIdOrgIdRef, domainName);

		//First check to set state for showSignInScreen and checkActivityRef
		checkIdleTime(logOutTimeout, checkActivityRef, setShowSignInScreen);

		//when clicking on any area under <html>, reset COOKIE_LASTEST_ACTIVITY_DETECTED_DATETIME to make sure user is actively working on website.
		['keyup', 'mousedown'].forEach((event) => {
			document.documentElement.addEventListener(event, setActivityDetectedTime);
		});

		//Start interval
		const interval = setInterval(() => {
			if (isSigningOutRef.current) {
				clearInterval(interval);
				return;
			}

			//disable 'Back to Sign In' button if another browser tab click on that button.
			checkAndDisableBackToSignInButton(disableLogOutButtonRef, setDisableLogOutButton);

			//When user changes his org in tab A, it will be refreshed in changeOrg event.
			//Then, other tabs will be refreshed based on COOKIE_CURRENT_USERID_ORGID changed
			const currentCookieUserOrgId = getCookie(COOKIE_NAMES.CURRENT_USERID_ORGID);
			if (hasOrgChanged(currentCookieUserOrgId, currentUserIdOrgIdRef.current)) {
				window.location.href = portalUrl;
				clearInterval(interval);
				return;
			}

			if (isPlatformLogin) {
				checkIdleTime(logOutTimeout, checkActivityRef, setShowSignInScreen);
			}
		}, 1000);
	}, []);

	const signOut = (urlParam?: string) => signOutUser(isSigningOutRef, portalUrl, domainName, urlParam);

	const changeOrg = (orgId: string) => {
		// Fire off a POST request to the portal application.
		// This creates a temp form and then submits it
		// this will still perform the POST request to change
		// orgs and redirect the user at the same time to the portal
		const formEl = document.createElement('form');
		formEl.action = `${portalUrl}/UpdateUserOrg/${orgId}`;
		formEl.method = 'post';
		document.body.appendChild(formEl);
		formEl.submit();
	};

	const tsmLogOut = () => {
		logoutOfTsm(portalUrl);
	};

	return { showSignInScreen, disableLogOutButton, signOut, changeOrg, tsmLogOut, manageAccountUrl } as UseUserSession;
}

export default useUserSession;

export const initialiseIntercom = (intercomAppId, intercomUserData) => {
    window.Intercom('boot', {
        api_base: "https://api-iam.intercom.io",
        app_id: intercomAppId,
        name: intercomUserData.userFullName,
        user_id: intercomUserData.userId,
        "Role": intercomUserData.userRole,
        "Organisation": intercomUserData.orgName,
        "Organisation_ID": intercomUserData.orgId
    });
};
import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import './App.scss';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import DashboardRoute from './components/routes/DashboardRoute';
import SettingsRoute from './components/routes/SettingsRoute';
import GlobalToasts from './components/GlobalToasts/GlobalToasts';
import GlobalConfirm from './components/GlobalConfirm/GlobalConfirm';
import SideMenu from './components/SideMenu/SideMenu';
import HistoryRoute from './components/routes/HistoryRoute';
import RegistrationsRoute from './components/routes/RegistrationsRoute';
import RegistrationDetailRoute from './components/routes/RegistrationDetailRoute';
import { PermissionsProvider } from './PermissionsContext';
import GlobalMessage from './components/GlobalMessage/GlobalMessage';
import GlobalAlert from './components/GlobalAlert/GlobalAlert';

import { DocumentViewerContextProvider } from '@practicehub/central';
import { AppHeader, BackToSignInModal, TsmBanner, AppHeaderNotificationContent, withFeatureConfig, useGlobalSearch } from '@practicehub/assets';
import { ENABLE_APP_HEADER_NOTIFICATION } from './Constants';
import Unauthorised from './components/Common/Unauthorised';
import PracticeApi from './api/practiceApi';

function App(props) {
    const [showNotification, setShowNotification] = useState(false); 

    useEffect(() => {
        if (props.featureConfigValues) {
            const appHeaderNotificationFeatureFlag = props.featureConfigValues.find(c => c.key == ENABLE_APP_HEADER_NOTIFICATION)?.value;
            setShowNotification((appHeaderNotificationFeatureFlag));
        }
    }, [props.featureConfigValues]);

    const onSubmitUpgradeForm = (formValues) => {
        return PracticeApi.submitUpgradeForm({
            ...formValues,
            orgGuid: props.userSession.user.orgId,
            orgName: props.userSession.user.orgName,
        });
    }

    return (
        <BrowserRouter>
            <>
                <div className="ph-header">
                    <AppHeader
                        logoUrl={props.appSettings.logoUrl}
                        portalUrl={props.appSettings.portalUrl}
                        manageAccountUrl={props.userSession.manageAccountUrl}
                        showManageAccount={!props.userSession.user.isAvantAdmin}
                        currentOrg={{
                            organisationName: props.userSession.user.orgName,
                            organisationId: props.userSession.user.orgId,
                            practiceTypeId: props.userSession.user.orgPracticeTypeId,
                            practiceTypeName: props.userSession.user.orgPracticeTypeName,
                            isTrialPlan: props.userSession.user.orgIsTrialPlan,
                            trialEndDateUtc: props.userSession.user.orgTrialEndDateUtc,
                        }}
                        onSubmitUpgradeForm={onSubmitUpgradeForm}
                        orgs={props.userSession.user.affiliations}
                        onSignOut={props.userSession.signOut}
                        onOrgChange={props.userSession.changeOrg}
                        getGlobalSearch={() => useGlobalSearch(props.appSettings.portalUrl, props.appSettings.practiceHubProxyApiUrl)}
                        userFirstName={props.userSession.user.firstName}
                        userLastName={props.userSession.user.lastName}
                        userEmail={props.userSession.user.email}
                        userPhone={props.userSession.user.phone}
                        notification={props.userSession.useAppHeaderNotification.notification}
                        updateNotification={props.userSession.useAppHeaderNotification.updateNotification}
                        notificationContentComponent={AppHeaderNotificationContent}
                        showNotification={showNotification}
						isAvantAdmin={props.userSession.user.isAvantAdmin}
                    />
                </div>
                <div className="ph-nav">
                    <SideMenu/>
                </div>
                <div>
                    <TsmBanner
                        showBanner={props.userSession.user.isAvantAdmin && props.userSession.user.orgName != null}
                        impersonationId={props.userSession.user.impersonationId}
                        impersonationFirstName={props.userSession.user.impersonationFirstName}
                        impersonationLastName={props.userSession.user.impersonationLastName}
                        orgName={props.userSession.user.orgName}
                        onTsmLogout={props.userSession.tsmLogOut}
                    />
                </div>
                <div className="ph-mainContent">
                    <PermissionsProvider>
                        <div>
                            <GlobalAlert />
                            <Switch>
                                <Route exact path="/">
                                    <DashboardRoute />
                                </Route>
                                <Route path="/settings">
                                    <SettingsRoute />
                                </Route>
                                <Route exact path="/registrations/record/:id/history/">
                                    <HistoryRoute />
                                </Route>
                                <Route path="/registrations-list">
                                    <RegistrationsRoute />
                                </Route>
                                <Route exact path="/record/:id">
                                    <RegistrationDetailRoute />
                                </Route>
                                <Route exact path="/unauthorised">
                                    <Unauthorised />
                                </Route>
                            </Switch>
                        </div>
                        <GlobalToasts />
                        <GlobalConfirm />
                        <GlobalMessage />
                    </PermissionsProvider>
                </div>
                {ReactDOM.createPortal(
                    <BackToSignInModal
                        showScreen={props.userSession.showSignInScreen}
                        userFirstName={props.userSession.user.firstName}
                        userLastName={props.userSession.user.lastName}
                        disableLogOutButton={props.userSession.disableLogOutButton}
                        onSignOut={props.userSession.signOut}
                    />,
                    document.getElementById('root-backToSignIn')
                )}
            </>
        </BrowserRouter>
    );
}

export default withFeatureConfig(App, [ENABLE_APP_HEADER_NOTIFICATION]);

import "core-js/stable";
import "regenerator-runtime/runtime";
import './styles/main.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import './custom.scss';
import App from './App';
import UserSessionProvider from './UserSessionProvider';
import { useUserSessionApi, FeatureConfigProvider, httpClientFactory, appendTokenToRequests } from '@practicehub/assets';
// MSAL imports
import { PublicClientApplication, EventType, InteractionRequiredAuthError } from "@azure/msal-browser";
import { msalConfig, loginRequest } from "./authConfig";
import { ApiUtility } from '../lib/utils/ApiUtility';
import { initialiseHeap } from './heapIntegration';
import { initialiseIntercom } from './intercomIntegration';
import * as Api from "./api";

export const msalInstance = new PublicClientApplication(msalConfig);

// Set active acccount on page load
const accounts = msalInstance.getAllAccounts();

if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event) => {
    // set active account after redirect
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        const account = event.payload.account;
        msalInstance.setActiveAccount(account);
    }
}, error => {
    console.log('error', error);
});

async function obtainAccessToken() {
    try {
        // If valid token available acquire then return it
        const tokenResponse = await msalInstance.acquireTokenSilent(loginRequest);
        return tokenResponse.accessToken;
    } catch (error) {
        //Acquire token silent failure, and send an interactive request
        if (error instanceof InteractionRequiredAuthError) {
            try {
                // Will require the user's to unblock popups for our domains. But is a smoother expierience.
                // const tokenResponse = await msalInstance.acquireTokenPopup(loginRequest);
                //return tokenResponse.accessToken;

                await msalInstance.loginRedirect(loginRequest);
            } catch (errorFromTryingToAcquireTokenFromPopup) {
                console.log(errorFromTryingToAcquireToken);
            }
        }
        console.log(error);
    }
}

msalInstance.initialize().then(() => {
    // handle auth redirect/do all initial setup for msal
    msalInstance.handleRedirectPromise().then(authResult => {
        // Check if user signed in 
        const account = msalInstance.getActiveAccount();
        if (!account) {
            // redirect anonymous user to login page 
            msalInstance.loginRedirect(loginRequest);
            return;
        }

        obtainAccessToken().then(() => {
            init();
        });

    }).catch(err => {
        console.log(err);
    });
});

const init = () => {
    const practiceHubProxyApiUrl = '/PracticeHubProxy';
    const userSessionApi = useUserSessionApi(practiceHubProxyApiUrl);

    userSessionApi.getUserWithAffiliationsAndAffiliations((userResponse) => {
        Api.affiliationsApi.setAffiliation().then(sessionDetails => {
            const account = msalInstance.getActiveAccount();
            const { isActive, inactivePageUrl } = sessionDetails;
            
            if (!isActive) {
                window.location.href = inactivePageUrl;
                return;
            }

            const { isProvisionedForCurrentApp } = sessionDetails;

            const { isAvantAdmin, orgId, orgName, userId, userCurrentRole, firstName, lastName } = userResponse;
            const appSettings = {
                isPlatformLogin: account.tenantId.toLowerCase() === window.PH_SignUpOrSignInPolicy.toLowerCase(),
                logOutTimeout: window.PH_LogOutTimeout,
                portalUrl: window.PH_portalUrl,
                logoUrl: window.PH_LogoUrl,
                practiceHubProxyApiUrl: practiceHubProxyApiUrl,
                apiUrl: '',
                domainName: window.PH_DomainName,
                cdnUrl: window.PH_cdnUrl,
            }
            
            if (!isProvisionedForCurrentApp) {
                window.location.href = "/App/NotProvisioned";
                return;
            }

            if (isAvantAdmin && orgName != null) {
                document.body.classList.add('is-impersonating');
            }

            initialiseHeap(window.PH_HeapAppId, {
                userId: userId,
                orgId: orgId,
                orgName: orgName,
                userRole: userCurrentRole,
                userFullName: `${firstName} ${lastName}`
            });
            initialiseIntercom(window.PH_IntercomAppId, {
                userFullName: `${firstName} ${lastName}`,
                userId: userId,
                userRole: userCurrentRole,
                orgName: orgName,
                orgId: orgId,
                orgType: userResponse.orgPracticeTypeName
            });

            ReactDOM.render(
                <React.StrictMode>
                    <FeatureConfigProvider portalUrl={practiceHubProxyApiUrl}>
                        <UserSessionProvider
                            appSettings={appSettings}
                            user={userResponse}
                            render={
                                userSession => <App userSession={userSession} appSettings={appSettings} />
                            }
                        />
                    </FeatureConfigProvider>
                </React.StrictMode>,
                document.getElementById('root')
            );
        });
        
    });
}

const instance = httpClientFactory(ApiUtility.getBaseUrl());
appendTokenToRequests(instance, obtainAccessToken);

instance.interceptors.response.use(
    response => response,
    error => ApiUtility.errorResponse(error)
)

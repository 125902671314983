import { Organisation } from 'components/OrgSwitcher';
import TextInput from 'components/TextInput';
import React, { useCallback, useState } from 'react';
import Button from '../../Button/Button';
import Modal from '../../Modal/Modal';
import Alert from '../../Alert/Alert';
import Icon, { library } from 'components/Icons/Icon';
import './trialBanner.scss';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import { useForm } from 'components/useForm';
import { ValidationRules } from 'components/useForm/type';
import { faClock as faSolidClock } from '@fortawesome/pro-regular-svg-icons/faClock';
import { faEnvelope as faLightEnvelope } from '@fortawesome/pro-light-svg-icons/faEnvelope';
import { faPhone as faLightPhone } from '@fortawesome/pro-light-svg-icons/faPhone';

library.add(faSolidClock, faLightEnvelope, faLightPhone);

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSameOrBefore);
dayjs.extend(isTomorrow);

interface TrialBannerProps {
	org: Organisation;
	user: { userFirstName: string; userLastName: string; userEmail: string; userPhone: string };
	onSubmitHandler: (t: { Name: string; Email: string; ContactNumber: string }) => Promise<void>;
}

export const validationRules: ValidationRules = {
	Name: (name: string) => {
		if (!name) {
			return 'Name cannot be empty.';
		}
		return null;
	},

	Email: (Email: string) => {
		if (!Email) {
			return 'Email cannot be empty.';
		}
		if (!/^[\w\.!#$%^&*\(\)\{\}\[\]\\\+\/=<>|?,-:"';]+@([\w-]+\.)+[\w-]{2,4}$/.test(Email)) {
			return 'Email format is invalid.';
		}
		return null;
	},

	ContactNumber: (contactNumber: string) => {
		if (!contactNumber) {
			return 'Contact Number cannot be empty.';
		}
		if (!/^\d{10,}$/.test(contactNumber)) {
			return 'Contact number is invalid.';
		}
		return null;
	},
};

const TrialBanner: React.FC<TrialBannerProps> = ({ org, user, onSubmitHandler }) => {
	const { userFirstName, userLastName, userEmail, userPhone } = user;
	const [loading, setLoading] = useState(false);

	const generateBannerMessage = (trialEndsIn: number) => {
		const endDayText =
			trialEndsIn > 1
				? `in ${trialEndsIn} days`
				: trialEndsIn === 1
				? 'tomorrow'
				: trialEndsIn === 0
				? 'today'
				: `ended ${Math.abs(trialEndsIn)} days ago`;

		return trialEndsIn < 0 ? `Your trial has ended` : `Your trial ends ${endDayText}`;
	};

	const { trialEndDateUtc } = org;

	const trialEndsIn = React.useMemo(() => {
		if (trialEndDateUtc) {
			const trialEndDateLocal = dayjs.utc(trialEndDateUtc).tz(dayjs.tz.guess()).startOf('day');
			return trialEndDateLocal.diff(dayjs().startOf('day'), 'day');
		}
		return 0;
	}, [trialEndDateUtc]);

	const trialExpired = trialEndsIn < 0;

	const initialValues = {
		Name: `${userFirstName} ${userLastName}`,
		Email: userEmail,
		ContactNumber: userPhone,
	};

	const { values, handleChange, handleSubmit, hasError, errors, resetForm } = useForm(
		{
			...initialValues,
		},
		() => {
			setLoading(true);
			onSubmitHandler(values).then(() => {
				setLoading(false);
				setShowThankYou(true);
			});
		},
		validationRules
	);

	const missingRequiredFields = !values.Name?.trim() || !values.Email?.trim() || !values.ContactNumber?.trim();
	const buttonSubmitDisabled = hasError || loading || missingRequiredFields;

	const [showUpgradeModal, setShowUpgradeModal] = useState(false);
	const [showThankYou, setShowThankYou] = useState(false);
	const upgradeClickHandler = useCallback(() => {
		setShowThankYou(false);
		setShowUpgradeModal(true);
		resetForm();
	}, []);

	const closeUpgradeModal = useCallback(() => {
		setShowUpgradeModal(false);
	}, []);

	if (!trialEndDateUtc) {
		return null;
	}

	const thankYou = () => (
		<div className="thank-you">
			<h3>Thank You!</h3>
			<p>We've received your details.</p>
			<p>Someone from our team will contact you soon.</p>
		</div>
	);

	return (
		<div className="ph-trial-message">
			<Alert
				message={
					<>
						{generateBannerMessage(trialEndsIn)}
						<Button buttonText="Subscribe Now" onClick={upgradeClickHandler} size="medium" />
					</>
				}
				showCloseButton={false}
				state="isWarning"
				icon={['far', 'clock']}
			/>

			<Modal
				show={trialExpired ? true : showUpgradeModal}
				title="Subscribe Now"
				className="ph-upgrade-modal"
				size="medium"
				onClose={closeUpgradeModal}
				onCancel={closeUpgradeModal}
				showFooter={false}
				modalClosable={!trialExpired}
			>
				{showThankYou && thankYou()}
				{!showThankYou && (
					<>
						<div className="ph-upgrade-copy">
							<h2>{trialExpired ? 'Your trial has ended' : 'Enjoying your free trial?'}</h2>
							<h4>Keep your practice running smoothly by subscribing today!</h4>
							<p>Subscriptions start from AUD $1,188/year</p>
						</div>
						<div className="ph-upgrade-content">
							<div className="content-left">
								<div className="upgrade-form">
									<h4>
										{trialExpired
											? 'Contact us to Subscribe now'
											: 'Contact  us before your trial ends'}
									</h4>
									<form onSubmit={handleSubmit}>
										<TextInput
											value={values.Name}
											onChange={handleChange}
											error={errors['Name'] ?? ''}
											name="Name"
											label="Name"
											id="Name"
											isMandatory
										/>
										<TextInput
											value={values.Email}
											onChange={handleChange}
											name="Email"
											error={errors['Email'] ?? ''}
											label="Email"
											id="Email"
											isMandatory
										/>
										<TextInput
											type="number"
											ignoreSpecialCharacterForNumberInput={true}
											value={values.ContactNumber}
											onChange={handleChange}
											name="ContactNumber"
											error={errors['ContactNumber'] ?? ''}
											label="Contact Number"
											id="ContactNumber"
											isMandatory
										/>
										<Button
											id="button-upgrade-form-submit"
											state={buttonSubmitDisabled ? 'isDisabled' : 'isNormal'}
											onClick={handleSubmit}
											buttonText={loading ? 'Submitting' : 'Submit'}
										/>
									</form>
								</div>
								<ul>
									<li>
										<Icon icon={['fal', 'envelope']} />
										<a href="mailto:info.practicehub@avant.org.au">info.practicehub@avant.org.au</a>
									</li>
									<li>
										<Icon icon={['fal', 'phone']} />
										1800 010 236
									</li>
								</ul>
							</div>
							<div className="content-right">
								<p>Here’s what you’ll continue to enjoy:</p>
								<ul>
									<li>
										<strong>Risk Management</strong>
										<p>Capture and manage incidents, feedback, risks and more with ease.</p>
									</li>
									<li>
										<strong>Management Support</strong>
										<p>
											Optimise practice administration by streamlining your systems and processes.
										</p>
									</li>
									<li>
										<strong>Accreditation</strong>
										<p>
											Simplify compliance by managing policies and storing evidence to meet
											healthcare standards.
										</p>
									</li>
									<li>
										<strong>HR Management</strong>
										<p>
											Accelerate team onboarding and induction, plus easily assign and track
											compliance for key legislative requirements.
										</p>
									</li>
								</ul>
							</div>
						</div>
					</>
				)}
			</Modal>
		</div>
	);
};

export default TrialBanner;

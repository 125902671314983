import { httpClientFactory, getUrlComponents } from '@practicehub/assets';
import { apiUrl } from '../connections';

export const get = (endpoint, skipCatch) => {

	const { baseURL, path } = getUrlComponents(`${apiUrl}${endpoint}`);
	const instance = httpClientFactory(baseURL);

	return instance.get(
		path,
		{ headers: { Accept: 'application/json' } })
		.then((res) => res.data)
		.catch((error) => { skipCatchFn(error, skipCatch) });
};

export const _delete = (endpoint, values, skipCatch) => {
	const { baseURL, path } = getUrlComponents(`${apiUrl}${endpoint}`);
	const instance = httpClientFactory(baseURL);

	return instance.delete(
		path,
		values,
		{ headers: { Accept: 'application/json' } })
		.then((res) => res.data)
		.catch((error) => { skipCatchFn(error, skipCatch) });
};

export const getWithoutApiPrefix = (endpoint, skipCatch) => {

	const truncatedApiUrl = apiUrl.replace('/api', '');
	const { baseURL, path } = getUrlComponents(`${truncatedApiUrl}${endpoint}`);
	const instance = httpClientFactory(baseURL);

	return instance.get(
		path,
		{ headers: { Accept: 'application/json' } })
		.then((res) => res.data)
		.catch((error) => { skipCatchFn(error, skipCatch) });
};

export const post = (endpoint, values, skipCatch) => {
	const { baseURL, path } = getUrlComponents(`${apiUrl}${endpoint}`);
	const instance = httpClientFactory(baseURL);

	return instance.post(
		path,
		values,
		{ headers: { Accept: 'application/json' } })
		.then((res) => res.data)
		.catch((error) => { skipCatchFn(error, skipCatch) });

};

export const postWithoutApiPrefix = (endpoint, values, skipCatch) => {
	const { baseURL, path } = getUrlComponents(`${endpoint}`);
	const instance = httpClientFactory(baseURL);

	return instance.post(
		path,
		values,
		{ headers: { Accept: 'application/json' } })
		.then((res) => res.data)
		.catch((error) => { skipCatchFn(error, skipCatch) });

};

export const put = (endpoint, values, skipCatch) => {

	const { baseURL, path } = getUrlComponents(`${apiUrl}${endpoint}`);
	const instance = httpClientFactory(baseURL);

	return instance.put(
		path,
		values,
		{ headers: { Accept: 'application/json' } })
		.then((res) => res.data)
		.catch((error) => { skipCatchFn(error, skipCatch) });

};

export const patch = (endpoint, patch, skipCatch) => {

	const { baseURL, path } = getUrlComponents(`${apiUrl}${endpoint}`);
	const instance = httpClientFactory(baseURL);

	return instance.patch(
		path,
		patch,
		{ headers: { Accept: 'application/json' } })
		.then((res) => res.data)
		.catch((error) => { skipCatchFn(error, skipCatch) });

};

export const postWithAttachments = (endpoint, payload, attachments, skipCatch) => {

	const { baseURL, path } = getUrlComponents(`${apiUrl}${endpoint}`);
	const instance = httpClientFactory(baseURL);

	const form = new FormData();
	form.append('data', JSON.stringify(payload));

	if (attachments != null) {
		Array.from(attachments).forEach((a) => {
			form.append('files', fileInput.files[0]);
		});
	}

	return instance.post(
		path,
		form,
		{ headers: { Accept: 'application/json' } })
		.then((res) => res.data)
		.catch((error) => { skipCatchFn(error, skipCatch) });
};

export const postWithAttachment = (endpoint, values, attachment, skipCatch) => {

	const { baseURL, path } = getUrlComponents(`${apiUrl}${endpoint}`);
	const instance = httpClientFactory(baseURL);

	const form = new FormData();
	form.append('data', JSON.stringify(values));
	form.append('file', attachment);

	return instance.post(
		path,
		form,
		{ headers: { Accept: 'application/json' } })
		.then((res) => res.data)
		.catch((error) => { skipCatchFn(error, skipCatch) });
};

function skipCatchFn(error, skipCatch) {
	if (skipCatch) {
		throw error;
	} else {
		return error;
	}
}
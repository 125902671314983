import useFeatureConfig from 'components/useFeatureConfig';

const withFeatureConfig = (
	Component: React.ElementType,
	configKeys: string[],
	baseUrl: string | null,
	// this is for new permissions
	featureConfigUrl?: string
) => {
	const Wrapper = (props: object) => {
		const featureConfigValues = useFeatureConfig(configKeys, baseUrl, featureConfigUrl);

		return <Component featureConfigValues={featureConfigValues} {...props} />;
	};

	return Wrapper;
};

export default withFeatureConfig;
